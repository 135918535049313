<template>
  <div>
    <page-heading
      :heading="
        procedure.name + ' (' + (baseInfo.procedureUserOwner && baseInfo.procedureUserOwner.user ? baseInfo.procedureUserOwner.user.full_name : '') +  ')'
    ">
      <span slot="help"></span>
      <div slot="right"></div>
    </page-heading>


    <el-card>
      <div style="display: flex; justify-content: space-between">
        <div class="smz-card-name">Сводка по годовому бонусу</div>
        <div v-if="baseInfo.procedureUserOwner" style="">
          <el-button
            v-if="$canAndRulesPass(baseInfo.procedureUserOwner, 'year_bonus_user.agree')"
            type="success"
            size="mini"
            :loading="loadingAgreed"
            :disabled="baseInfo.procedureUserOwner.agreed"
            @click="markAgreed"
          >
            <span v-if="!baseInfo.procedureUserOwner.agreed">Подтвердите ознакомление</span>

          </el-button>
          <el-button
            v-if="baseInfo.procedureUserOwner.agreed"
            disabled
            type="success"
            size="mini"
          >
            Ознакомлен
          </el-button>

        </div>
      </div>

      <table
        class="smz-base-table"
        v-loading="loadingHeader"
      >
        <thead>
        <tr>
          <th>Вид вознаграждения</th>
          <th>Количество сотрудников</th>
          <th>Годовой бонус, итого руб.</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Годовой бонус</td>
          <td>{{ baseInfo.procedure_users.length }}</td>
          <td>{{ baseInfo.procedureUserOwner.total_year_bonus | numberFormat  }}</td>
        </tr>
        </tbody>
      </table>

    </el-card>

    <el-card style="margin-top: 30px" id="procedure-details-card">
      <div class="smz-card-name">Детали</div>

      <el-row :gutter="20">
        <el-col :span="4" v-loading="loadingHeader">
          <div
            v-if="baseInfo.procedureUserOwner.user_id === user.id"
            style="margin-bottom: 20px"
          >
            <el-button
              :disabled="!$canAndRulesPass(baseInfo.procedureUserOwner, 'year_bonus_user.see_my_bonus')"
              style="width: 100%; padding: 8px 5px"
              :type="selectedEmployeeId===user.id ? 'primary' : ''"
              @click="loadMyInfo"
            >
              Мой годовой бонус
              <el-popover
                placement="top"
                width="400"
                trigger="click"
              >
                <el-button
                  slot="reference"
                  class="table-action-button open-desc-button"
                  type="text"
                  round
                  size="mini"
                  icon="fas fa-info-circle"
                ></el-button>
                <div>Доступно для просмотра после того, как Ваш Руководитель подтвердит ознакомление</div>
              </el-popover>
            </el-button>

          </div>

          <div style="margin-bottom: 10px;text-transform: uppercase; font-weight: bold; color: #145969; font-size: 14px;line-height: 1;">Годовой бонус подчиненных</div>
          <div>
            <el-radio-group
              v-model="selectedEmployeeId"
              style="width: 100%"
              @change="loadEmployeeInfo"
            >
              <div v-for="procedure_user in baseInfo.procedure_users">
                <el-radio-button class="year-bonus-user-radio" :label="procedure_user.user_id">
                  <div v-if="!procedure_user.history_user">Нет данных на дату процедуры</div>
                  <div v-else>{{procedure_user.history_user.last_name}} {{procedure_user.history_user.first_name}} {{procedure_user.history_user.middle_name}}</div>
                </el-radio-button>
              </div>
            </el-radio-group>
          </div>
        </el-col>
        <el-col :span="20" v-loading="loadingEmployeeInfo">
          <div v-if="!employeeInfo.procedure_user" style="text-align: center;">Выберите подчиненного</div>
          <div v-else-if="!employeeInfo.procedure_user.history_user" style="text-align: center;">Нет данных на дату процедуры</div>
          <div v-else>
            <div class="smz-table-name">Личная информация</div>
            <table class="smz-base-table">
              <thead>
              <tr>
                <th>ФИО</th>
                <th>Должность</th>
                <th>Дирекция</th>
                <th>Бенд</th>
                <th>Целевой процент премии по состоянию на 31.12.{{procedure.year}}</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{{employeeInfo.procedure_user.history_user.last_name}} {{employeeInfo.procedure_user.history_user.first_name}} {{employeeInfo.procedure_user.history_user.middle_name}} </td>
                <td>{{employeeInfo.procedure_user.history_user.position}}</td>
                <td>{{employeeInfo.procedure_user.history_user.direction}}</td>
                <td>{{employeeInfo.procedure_user.history_user.band}}</td>
                <td>{{employeeInfo.procedure_user.history_user_last_day_of_year.year_bonus_percent}} %</td>
               </tr>
              </tbody>
            </table>



            <div class="smz-table-name" style="margin-top: 40px">Корпоративные КПЭ. Финансовый мультипликатор</div>
            <table class="smz-base-table">
              <thead>
              <tr>
                <th>Группа показателей</th>
                <th>Номер КПЭ</th>
                <th>Показатель/задача</th>
                <th>Единица измерения</th>
                <th>Тип</th>
                <th>Min уровень, %</th>
                <th>Целевое значение показателя</th>
                <th>Max уровень, %</th>
                <th>Коэффициент выплаты</th>
                <th>Вес показателя, план</th>
                <th>Фактическое значение показателя</th>
                <th>% выполнения</th>
                <th>Вес показателя, факт</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(kpi, index) in procedure.meta.kpis">
                <td>{{kpi.group}}</td>
                <td>{{index+1}}</td>
                <td>{{kpi.name}}</td>
                <td>{{kpi.unit}}</td>
                <td>{{kpi.type}}</td>
                <td>{{kpi.min_level}}</td>
                <td>{{kpi.target}}</td>
                <td>{{kpi.max_level}}</td>
                <td>{{kpi.pay_factor}}</td>
                <td>{{kpi.weight_plan}}</td>
                <td>{{kpi.fact}}</td>
                <td>{{kpi.fact_percent}}</td>
                <td>{{kpi.weight_fact}}</td>
              </tr>
              </tbody>
            </table>


            <table class="summary-table">
              <tbody>
              <tr>
                <td class="summary-table-name">Итого вес корпоративных КПЭ</td>
                <td>Вес показателя план: {{procedure.meta.kpis.reduce((n, {weight_plan}) => n + +weight_plan, 0)}}%</td>
                <td>Вес показателя факт: {{procedure.meta.kpis.reduce((n, {weight_fact}) => n + +weight_fact, 0)}}%</td>
              </tr>
              </tbody>
            </table>


            <div class="smz-table-name" style="margin-top: 40px">Сквозной КПЭ. Оценка руководителей</div>
            <table class="smz-base-table">
              <thead>
              <tr>
                <th>Показатель/задача</th>
                <th>Единица измерения</th>
                <th>Тип</th>
                <th>Ниже ожиданий</th>
                <th>Положительная</th>
                <th>Высокая</th>
                <th>Исключительная</th>
                <th>Оценка за год</th>
                <th>Индивидуальный мультипликатор</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Итоговая оценка эффективности деятельности</td>
                <td>%</td>
                <td>Качественная</td>
                <td>{{countThroughKpiByEvaluation(12)}} %</td>
                <td>{{countThroughKpiByEvaluation(13)}} %</td>
                <td>{{countThroughKpiByEvaluation(14)}} %</td>
                <td>{{countThroughKpiByEvaluation(15)}} %</td>
                <td>{{getYearPoint()}}</td>
                <td>{{countIndividualMultiplier()}} %</td>
              </tr>
              </tbody>
            </table>



            <div class="smz-table-name" style="margin-top: 40px">Расчет по месяцам</div>
            <table class="smz-base-table">
              <thead>
              <tr>
                <th>Месяц</th>
                <th>Оклад, руб.</th>
                <th>Целевой % годового бонуса</th>
                <th>Целевой размер годового бонуса, руб</th>
                <th>Финансовый мультипликатор, %</th>
                <th>Индивидуальный мультипликатор, %</th>
                <th>Годовой бонус к выплате</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="statRow in employeeInfo.stats">
                <td>{{statRow.month}}</td>
                <td>{{statRow.current_salary | numberFormat }}</td>
                <td>{{statRow.year_bonus_percent | numberFormat }} %</td>
                <td>{{statRow.year_bonus_value | numberFormat }}</td>
                <td>{{statRow.financial_multiplier | numberFormat }} %</td>
                <td>{{statRow.individual_multiplier | numberFormat }} %</td>
                <td>{{statRow.year_bonus_to_pay | numberFormat }}</td>
              </tr>
              </tbody>
            </table>


            <table class="summary-table">
              <tbody>
              <tr>
                <td class="summary-table-name">Итого</td>
                <td>Оклад за год: {{employeeInfo.statsSummary.current_salary | numberFormat }}</td>
                <td>Целевой размер годового бонуса: {{employeeInfo.statsSummary.year_bonus_value | numberFormat }}</td>
                <td>Годовой бонус к выплате: {{employeeInfo.statsSummary.year_bonus_to_pay | numberFormat }}</td>
              </tr>
              </tbody>
            </table>


          </div>
        </el-col>
      </el-row>


    </el-card>

  </div>

</template>

<script>

import requestSender from "@/api/base/requestSender";
import ClickToEdit from "@/components/ClickToEdit.vue";
import PageHeading from "@/components/PageHeading.vue";
import {mapGetters} from "vuex";

export default {
  name: "YearBonusTemplate",
  components: {PageHeading, ClickToEdit},
  props: {
    procedure: {type: Object, required: true},
  },

  computed: {
    ...mapGetters(['user']),
  },

  data() {

    return {
      loadingHeader: false,
      loadingEmployeeInfo: false,
      loadingAgreed: false,

      evaluations: [],

      baseInfo:{
        procedure_users: [],
        procedureUserOwner: [],
      },

      selectedEmployeeId: null,
      employeeInfo: {},

    }
  },

  mounted() {
    this.getEvaluations();
    this.getHeaderInfo();
  },
  methods: {

    getEvaluations() {
      requestSender('get', 'evaluation/search')
        .then(({evaluations}) => {
          this.evaluations = evaluations;
        })
    },

    getHeaderInfo() {
      this.loadingHeader = true;

      requestSender('get', 'compensation-procedure-year-bonus/get-base-info', {
        procedure_id: this.procedure.id,
        user_id: parseInt(this.$route.params.owner_id),
        expand: 'user, permissions',
      })
        .then(data => {
          this.baseInfo.procedureUserOwner = data.procedure_user_owner;
          this.baseInfo.procedure_users = data.procedure_users;
        })
        .finally(() => {
          this.loadingHeader = false;
        });
    },

    loadMyInfo(){
      this.selectedEmployeeId = this.user.id;
      this.loadEmployeeInfo();
    },

    loadEmployeeInfo() {
      this.loadingEmployeeInfo = true;
      document.getElementById('procedure-details-card').scrollIntoView({ behavior: "smooth"});

      requestSender('get', 'compensation-procedure-year-bonus/get-employee-info', {
        procedure_id: this.procedure.id,
        employee_id: this.selectedEmployeeId,
      })
        .then(data => {
          this.employeeInfo = data.employee_info;
        })
        .finally(() => {
          this.loadingEmployeeInfo = false;
        });
    },


    countThroughKpiByEvaluation(evaluationId){
      const multiplierName = this.employeeInfo.procedure_user.history_user.band < 50 ? 'less50' : 'up50';
      return this.procedure.meta.individual_multiplier[multiplierName][evaluationId];
    },
    getYearPoint(){
      if( !this.employeeInfo.procedure_user.evaluate_final_efficiency_id ){
        return '';
      }
      return this.evaluations[4].find(e=>e.id===this.employeeInfo.procedure_user.evaluate_final_efficiency_id).name;
    },
    countIndividualMultiplier(){
      if( !this.employeeInfo.procedure_user.evaluate_final_efficiency_id ){
        return '';
      }
      return this.countThroughKpiByEvaluation(this.employeeInfo.procedure_user.evaluate_final_efficiency_id);
    },


    markAgreed(){
      this.loadingAgreed = true;

      requestSender('get', 'compensation-procedure-year-bonus/mark-agreed', {
        procedure_id: this.procedure.id,
        user_id: parseInt(this.$route.params.owner_id),
      })
        .finally(() => {
          this.getHeaderInfo();
          this.loadingAgreed = false;
        });
    },


  },


}
</script>

<style lang="scss">
.year-bonus-user-radio{
  border-left: 1px solid #DCDFE6;
  width: 100%;

  & .el-radio-button__inner {
    width: 100%;
    white-space: normal;
  }
}

.summary-table{
  margin-top: 15px;
  width: 100%;
  text-align: end;
  border-bottom: 1px solid #DCDFE6;
  color: #a1a1a1;

  &-name {
    text-align: start;
    font-weight: bold;
  }
}

</style>